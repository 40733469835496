<template>
 <b-container fluid>
  <b-row>
   <b-col lg="12">
    <iq-card>
     <template v-slot:headerTitle v-if="!propOpenedInModal">
      <h4 class="card-title">{{ cvCardTitle }}</h4>
     </template>
      <template v-slot:body>
       <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
        <form action="#">
         <div class="form-row">
          <div class="col-md-12 mb-3">
            <label for="validationword">{{cvWordLabel}}</label>
            <input
              v-model="vmGAME_HMFormData.word"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="col-md-12 mb-3">
            <label for="validationdifficulty">
              {{cvDifficultyLabel}}</label>
          <input
            v-model="vmGAME_HMFormData.difficulty"
            type="text"
            class="form-control"
            required
          />
          </div>
          <div class="col-md-12 mb-3">
            <label for="validationgameType">
              {{cvGameTypeLabel}}</label>
          <input
            v-model="vmGAME_HMFormData.game_type"
            type="text"
            class="form-control"
            required
          />
          </div>
        </div>
        <div class="form-group">
         <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
          <b-spinner label="Spinning"></b-spinner>
         </button>
         <button
          type="button"
          class="btn btn-primary"
          @click="game_hmAdd()">{{ cvSubmitBtn }} </button>
        </div>
      </form>
    </template>
  </iq-card>
 </b-col>
</b-row>
 <b-toast
  v-model="showToast"
  name="Toast"
  :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
  :variant="toastVariant"
  :title="toastTitle">
<div v-html="toastMsg"></div>
 </b-toast>
 </b-container>
</template>

<script>

import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit"
import { GameHm } from "../../../FackApi/api/gameHm"

export default {
  name: "GAME_HMAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add Game Hm",
      cvCardSubHeader: "Add Game Hm",
      cvSubmitBtn: "Add",
      cvWordLabel: "word",
      cvDifficultyLabel: "difficulty",
      cvGameTypeLabel: "Game Type",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "GAME_HM",
      vmGAME_HMFormData: Object.assign({}, this.initFormData()),
      vmGAME_HMDesc: null,
      vmGAME_HMType: null
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "word": "",
        "difficulty": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGAME_HMFormData) {
          if (!this.vmGAME_HMFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * game_hmAdd
     */
    async game_hmAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let gameHmAddResp = await GameHm.game_hmAdd(this, this.vmGAME_HMFormData)
        await ApiResponse.responseMessageDisplay(this, gameHmAddResp)
        if (gameHmAddResp && !gameHmAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGAME_HMAddModal", gameHmAddResp.resp_data)
        }
        this.vmGAME_HMFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at game_hmAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
